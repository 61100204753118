<template>
	<!--  -->
	<el-dialog :title="$t('i18nn_e6a250894a04db4c')" append-to-body :close-on-click-modal="false" :visible.sync="dialog" width="1000px">
		<div>
			<el-descriptions title="" v-loading="loading_load">
				<el-descriptions-item :label="$t('i18nn_b00576fed9b3a1f2')">{{detData.loginAccount}}</el-descriptions-item>
				<el-descriptions-item :label="$t('i18nn_604a0b11e916f387')">{{detData.loginPass}}</el-descriptions-item>
			</el-descriptions>
		</div>

		<div slot="footer" class="dialog-footer">
			<el-button type="primary" plain @click="dialog = false">{{$t('i18nn_4e9fc68608c60999')}}</el-button>
			<!-- <el-button type="warning" plain icon="el-icon-refresh" @click="ChangeSelOrderRateExpressData()">{{$t('i18nn_a9e1d1c2c5eb6525')}}</el-button> -->
		</div>
	</el-dialog>
</template>
<script>
	// import barcode from '@/components/Common/barcode.vue';

	export default {
		props: {
			openTime: {},
			id: {
				default: function() {
					return '';
				},
				type: String
			}
		},
		// components: {
		// 	barcode
		// },
		data() {
			return {
				dialog: false,
				loading_load: false,
				detData: {},
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
				//初始化
				this.dialog = true;
			}
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {

		},
		methods: {
			initData() {
				this.detData = {};
				if (this.id) {
					this.getDetData();
				} else {
					this.$message.warning("ID为空");
				}
			},

			//请求分页数据
			getDetData() {
				this.loading_load = true;
				this.$http
					.get(this.$urlConfig.WhSubUserInfo + "/" + this.id, {
						// offset: "0",
						// limit: "100",
						// goodsSku: this.goodsSku ? this.goodsSku : null,
					})
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_4195755cd53e871a'));
						console.log(data);
						this.loading_load = false;
						if (200 == data.code && data.data) {
							this.detData = data.data;
						} else {
							this.$alert(this.$t('tips.queryErrorFailed'), this.$t('tips.tipsTitle'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						this.loading_load = false;
						this.$alert(this.$t('tips.queryRequestFailed'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					});
			},
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
