<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader">
			<h3 class="buyerHeaderTit">
				<span>{{$t('i18nn_7a806e59f1e812b7')}}</span>
			</h3>
		</div> -->

		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">{{$t('i18nn_d8917510efdc82c9')}}</span>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<!-- <span class="" v-if="!isSel"> -->
					<el-button type="success" @click="openDioalog(null, $t('i18nn_c0246c55b9cac963'))" size="small" icon="el-icon-plus">{{$t('i18nn_c0246c55b9cac963')}}</el-button>
					<!-- </span> -->
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>

		<div class="filterCon" style="">
			<ul class="filterConList">
				<!-- <li>
					<span>{{$t('i18nn_4b2d7b585777fa99')}}</span>
					<cusSelFuzzy ref="cusSelFuzzy" @changeData="changCus"></cusSelFuzzy>
				</li> -->
				<!-- <li>
					<span>{{$t('i18nn_76dc341edac65885')}}</span>
					<el-select size="small" filterable clearable v-model="filterData.wh_biz_type"
						:placeholder="$t('i18nn_2ad108ab2c560530')" style="width: 100px;" @change="initData()">
						<el-option v-for="item in selectOption.wh_biz_type" :key="item.code" :label="$Utils.i18nCodeText(item)"
							:value="item.code">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ $Utils.i18nCodeText(item) }}</span>
								<span class="sel_option_code" style="">{{ item.code }}</span>
							</div>
						</el-option>
					</el-select>
				</li>
				<li>
					<span>{{$t('i18nn_c5400c966a9be32d')}}</span>
					<el-select size="small" filterable clearable v-model="filterData.wh_op_type"
						:placeholder="$t('i18nn_2ad108ab2c560530')" style="width: 100px;" @change="initData()">
						<el-option v-for="item in selectOption.wh_op_type" :key="item.code" :label="$Utils.i18nCodeText(item)"
							:value="item.code">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ $Utils.i18nCodeText(item) }}</span>
								<span class="sel_option_code" style="">{{ item.code }}</span>
							</div>
						</el-option>
					</el-select>
				</li>
				<li>
					<span>{{$t('i18nn_61fc9e07fff97930')}}</span>
					<el-select size="small" filterable clearable v-model="filterData.wh_op_service_type"
						:placeholder="$t('i18nn_2ad108ab2c560530')" style="width: 100px;">
						<el-option v-for="item in selectOption.wh_op_service_type" :key="item.code"
							:label="$Utils.i18nCodeText(item)" :value="item.code">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ $Utils.i18nCodeText(item) }}</span>
								<span class="sel_option_code" style="">{{ item.code }}</span>
							</div>
						</el-option>
					</el-select>
				</li> -->
				<li>
					<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
					<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
						maxlength="50" :clearable="true" @keyup.enter.native="initData()" style="width: 250px;"></el-input>
				</li>
				<li>
					<el-button icon="el-icon-search" size="small" type="primary" @click="initData()">
						{{ $t('i18nn_1e7246dd6ccc5539') }}</el-button>
				</li>
			</ul>
		</div>

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table id="out-table2" ref="multipleTable" :data="tableData" stripe :border="true"
				:height="$store.state.tableMaxHeight2" style="width: 100%" size="small">
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>
				<!-- {"bizType":"业务类型","opModel":"操作模块","feeType":"费用类型","feeTypeName":"费用名称"} -->
				<!-- isSub -->
				
				
				<!-- <el-table-column prop="isSub" :label="$t('i18nn_cdd4901a665110a9')">
					<template slot-scope="scope">
					
						<div>
							<el-tag v-if="1==scope.row.isSub">{{$t('i18nn_02ccd2cf723f9272')}}</el-tag>
							<el-tag type="info" v-else-if="0==scope.row.isSub">{{$t('i18nn_e76d885ae1c74d4f')}}</el-tag>
							<el-tag v-else>{{scope.row.isSub}}</el-tag>
						</div>
					</template>
				</el-table-column> -->
				<el-table-column prop="cusNo" :label="$t('i18nn_5e7740c52e22eefc')"></el-table-column>
				<!-- <el-table-column prop="logoAccount" :label="'logoAccount'"></el-table-column> -->
				<el-table-column prop="account" :label="$t('i18nn_b00576fed9b3a1f2')"></el-table-column>
				<!-- <el-table-column prop="parentId" :label="'主账户ID'"></el-table-column> -->
				
				
				<el-table-column prop="companyName" :label="$t('i18nn_2489ac08975334e4')"></el-table-column>
				<!-- <el-table-column prop="skuPrefix" :label="$t('i18nn_e4842b8507dd415c')"></el-table-column> -->
				<el-table-column prop="introducer" :label="$t('i18nn_00b8608bd21b2160')"></el-table-column>
				<!-- <el-table-column prop="whNo" :label="$t('i18nn_3e98a7801f9d8a6d')"></el-table-column> -->
				<!-- <el-table-column prop="cusContact" :label="$t('i18nn_c2d9cc88561f8cdc')">
					<template slot-scope="scope">

						<div>
							<div v-for="(item,index) in scope.row.cusContactArr" :key="index">
								<span>{{item.name}}:</span>
								<span>{{item.value}}</span>
							</div>
						</div>
					</template>
				</el-table-column> -->
				<!-- <el-table-column prop="userId" :label="'user_ID'"></el-table-column> -->
				<el-table-column prop="remark" :label="$t('i18nn_15b3627faddccb1d')">
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover" ><div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column>
				<el-table-column :label="$t('Storage.tableColumn.operation')" width="200px" fixed="right">
					<template slot-scope="scope">
						<!-- <div v-if="isSel"><el-button @click="selRow($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-magic-stick">{{$t("FormMsg.Select")}}</el-button></div> -->
						<div>
							<el-button @click="openView($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-view">{{$t('i18nn_f5765bd7956321f2')}}</el-button>
							<!-- <el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button> -->
						</div>
					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div>
		</div>
		<!-- 修改弹窗 -->
		<el-dialog :close-on-click-modal="false" :title="dialogFormMsg" append-to-body
			:visible.sync="dialogFormVisible" width="800px">
			<el-form ref="form" :rules="formRules" :model="form" label-width="150px" v-loading="loading"
				:element-loading-text="$t('i18nn_1b4525c800280581')">

				<!-- <el-form-item :label="$t('i18nn_76dc341edac65885')" prop="bizType">
					<el-select filterable clearable v-model="form.bizType" :placeholder="$t('i18nn_2ad108ab2c560530')"
						style="width: 200px;">
						<el-option v-for="item in selectOption.wh_biz_type" :key="item.code" :label="$Utils.i18nCodeText(item)"
							:value="item.code">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ $Utils.i18nCodeText(item) }}</span>
								<span class="sel_option_code" style="">{{ item.code }}</span>
							</div>
						</el-option>
					</el-select>
				</el-form-item>
 -->
				
				<!-- "loginAccount": null, //"子账号账户",
				"isAutoAccount": null, //"是否自动账号",
				"cusDto": {
					"companyName": null, //"子账户简称",
					"introducer": null, //"介绍人"
				}, -->
				
				<!-- <el-form-item :label="$t('i18nn_29991afa9781d554')" required>
					<cusSelFuzzy ref="cusSelFuzzy2" @changeData="changCus2"></cusSelFuzzy>
				</el-form-item> -->
				
				<el-form-item :label="$t('i18nn_8664edb3936db8b0')" prop="isAutoAccount">
					<!-- <el-input type="text" v-model="form.isAutoAccount" style="width: 200px;"></el-input> -->
					<el-switch v-model="form.isAutoAccount" active-value="1" inactive-value="0" :active-text="$t('i18nn_02ccd2cf723f9272')"
						:inactive-text="$t('i18nn_e76d885ae1c74d4f')">
					</el-switch>
				</el-form-item>

				<el-form-item :label="$t('i18nn_4438ee6b6104e469')" prop="loginAccount" v-if="0==form.isAutoAccount">
					<el-input type="text" v-model="form.loginAccount" maxlength="100" show-word-limit></el-input>
				</el-form-item>

				
				
				<el-form-item :label="$t('i18nn_e78b4579ca11c47f')" prop="" required>
					<el-input type="text" v-model="form.cusDto.companyName" maxlength="100" show-word-limit></el-input>
				</el-form-item>
				
				<el-form-item :label="$t('i18nn_00b8608bd21b2160')" prop="" required>
					<el-input type="text" v-model="form.cusDto.introducer" maxlength="100" show-word-limit></el-input>
				</el-form-item>


				<!-- <el-form-item :label="$t('Storage.tableColumn.remark')">
					<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark"
						maxlength="" show-word-limit style="width: 300px;"></el-input>
				</el-form-item> -->
			</el-form>

			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogFormVisible = false">{{ $t('FormMsg.Close') }}</el-button>
				<el-button type="primary" icon="el-icon-tickets" @click="submitForm('form')">{{ $t('FormMsg.Save') }}</el-button>
			</div>
		</el-dialog>
		
		<!-- 查看 -->
		<dialogSubUserView :openTime="ViewSubUserOpenTime" :id="SubUserId"></dialogSubUserView>
		
	</div>
</template>
<script>
	import {
		clearObjectVal
	} from '@/utils/common.js';
	
	// import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
	
	// import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';
	// import AccountUserList from '@/components/WarehouseCenter2/AccountBind/AccountUserList.vue';
	// import excelUtilsNew from '@/utils/excelUtilsNew.js';
	// import barcode from '@/components/Common/barcode.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	
	import dialogSubUserView from '@/components/StorageCenter/userMenus/dialogSubUserView.vue';
	
	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		//   meta: [{ // set meta
		//     name: '互易天下-买家中心-收货账单',
		//     content: '互易天下-厂家共享平台-买家中心-收货账单'
		//   }],
		//   // link: [{                 // set link
		//   //   rel: 'asstes',
		//   //   href: 'https://assets-cdn.github.com/'
		//   // }]
		// },
		// props: ['isDialog'],
		components: {
			// AccountUserList,
			// cusSelFuzzy,
			whNoSelect,
			dialogSubUserView
		},
		props: {
			// mobile:"",
			isSel: {
				default: function() {
					return false
				},
				type: Boolean
			},
			// status:{
			//   default: function() {
			//     return ''
			//   },
			//   type: String
			// },
		},
		data() {
			return {
				// cusUserId: '',
				ViewSubUserOpenTime:'',
				SubUserId:'',
				// UserInfo: this.$store.getters.getUserInfo,
				dialogFormVisible: false,
				dialogFormStatus: 0, //0-新增，1-修改
				dialogFormMsg: '',
				// dialogSelVisible: false,

				// CateValue: [],
				loading: false,
				form: {
					// "serviceType":null,
					// "bizType":null,//this.$t('i18nn_76dc341edac65885'),
					// "opModel":null,//this.$t('i18nn_c5400c966a9be32d'),
					// "feeType":null,//this.$t('i18nn_46c3f6e0f657e7a3'),
					// "feeTypeName":null,//this.$t('i18nn_a4eca6ff2588d791'),
					// userId:null,
					"loginAccount": null, //this.$t('i18nn_8a2be54a2e609dcc'),
					"isAutoAccount": '0', //this.$t('i18nn_9ea9185268cf2519'),
					"cusDto": {
						"companyName": null, //this.$t('i18nn_e78b4579ca11c47f'),
						"introducer": null, //this.$t('i18nn_00b8608bd21b2160')
					},

				},

				formRules: {
					loginAccount: [{
						required: true,
						message: this.$t('FormMsg.Please_select'),
						trigger: 'change'
					}],
					isAutoAccount: [{
						required: true,
						message: this.$t('FormMsg.Please_select'),
						trigger: 'change'
					}],
				},
				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					// wh_no: []
					wh_biz_type: [],
					wh_op_type: [],
					wh_op_service_type: [],
				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序
					// userName: '',
					// wh_op_service_type: '',
					// wh_biz_type: '',
					// wh_op_type: '',
					keyword:'',
					parentId: '',
					whNo: ''
				}
			};
		},
		activated() {
			this.initData();
		},
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			this.initData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				this.getPageData();
				// this.getDicData();
				// this.getWhNoListData();
				this.$nextTick(() => {
					this.$refs.multipleTable.doLayout();
				});
			},

			//打开账号选择弹窗
			// openSelDialog(){
			// 	this.dialogSelVisible = true;
			// },
			//选择仓库
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				this.initData();
			},
			// changCus(data) {
			// 	console.log('changCus', data);
			// 	// this.filterData.cusNo = data.cusNo;
			// 	this.filterData.parentId = data.userId;
			// 	this.initData();
			// },
			// changCus2(data) {
			// 	console.log('changCus', data);
			// 	// this.filterData.cusNo = data.cusNo;
			// 	this.form.userId = data.userId;
			// 	// this.initData();
			// },
			// changeDataWhNo(data){
			// 	console.log('changeDataWhNo',data);
			// 	this.form.whNo = data.code;
			// },
			//选择账号
			// selAccount(row){
			// 	console.log('selAccount',row);
			// 	this.form.bckId = row.userId;
			// 	this.form.userName = row.userName;
			// 	this.dialogSelVisible = false;
			// },
			//导出 excel
			// exportExcel() {

			// },
			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			//打开新增编辑，弹窗
			openDioalog(formParm, msg) {
				// console.log(formParm);
				this.dialogFormVisible = true;
				this.dialogFormMsg = msg;
				let form = Object.assign({}, formParm);
				console.log('form', form);

				// // 重置
				this.resetForm('form');
				if (null === formParm) {
					//新增
					// this.form.bckId = '';
					// this.form.userName = '';
					// this.form.remark = '';

					this.dialogFormStatus = 0;
					// form.subUserId = null;
					// form.userSubUserId = null;
					// form.state = true;
					//浅拷贝、对象属性的合并
					// this.form = form;
				} else {
					// 	//修改
					// this.dialogFormStatus = 1;
					// 	// // form.state = form.state === '0' ? true : false;
					// 	// // this.form.goodsSubjectId = this.CateValue[0];
					// 	// this.CateValue = [form.goodsSubjectId];
					// 	// //浅拷贝、对象属性的合并
					// this.form = form;
				}
			},
			
			//查看
			openView(event, row, index) {
				event.stopPropagation();
				// this.openDioalog(row, this.$t('FormMsg.Edit'));
				this.ViewSubUserOpenTime = new Date().getTime();
				this.SubUserId = row.userId;
			},
			
			//打开编辑
			// openEdit(event, row, index) {
			// 	event.stopPropagation();
			// 	this.openDioalog(row, this.$t('FormMsg.Edit'));
			// },

			//删除
			// delAction(event, row) {
			// 	this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
			// 		type: 'warning'
			// 	})
			// 		.then(() => {
			// 			this.delDataAction(event, row);
			// 		})
			// 		.catch(() => {});
			// },
			// //删除
			// delDataAction(event, row) {
			// 	event.stopPropagation();
			// 	console.log('delDataAction', row);

			// 	this.postData(this.$urlConfig.WhFeeTypeConfigDel + '/' + row.id, {}, 'delete');
			// },

			//提交信息
			submitForm(formName) {
				// if(!this.form.userId){
				// 	this.$message.warning(this.$t('i18nn_5184f7a4033b4969'));
				// 	return;
				// }
				
				this.$refs[formName].validate(valid => {
					if (valid) {
						let formData = Object.assign({}, this.form);
						//浅拷贝、对象属性的合并
						// if (0 === this.dialogFormStatus) {
						this.postData(this.$urlConfig.WhSubUserAdd, formData);
						// } else {
						// 	this.postData(this.$urlConfig.WhFeeTypeConfigUpdate, formData);
						// }
					} else {
						console.log('error submit!!');
						this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
						return false;
					}
				});
			},
			//重置输入框
			resetForm(formName) {
				console.log(formName);
				// console.log(this.$refs[formName]);

				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
					this.form = clearObjectVal(this.form);
				} else {
					console.log('this.$refs[formName]', this.$refs[formName]);
				}
				this.form.isAutoAccount = '0';
				this.$nextTick(()=>{
					this.$refs.cusSelFuzzy2.init('')
				})
				// this.CateValue = [];
				// if (this.$refs.hyUpLoadImg1) {
				// 	this.$refs.hyUpLoadImg1.initUrl('');
				// }
			},

			//提交信息
			postData(url, formData, type) {
				// let _this = this;
				this.loading = true;
				this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				// formData.state = formData.state ? '0' : '1';
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('tips.submitSuccess'));
					console.log(data);
					
					this.loading = false;
					this.loading_load = false;
					if (200 == data.code) {
						this.dialogFormVisible = false;
						this.getPageData();
						// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
						//   type: 'success',
						//   //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						// });
						// this.$message.success(this.$t('tips.submitSuccess'));
						if ('delete' == type) {
							this.$message({
								type: 'success',
								message: this.$t('tips.successfullyDelete')
							});
						} else {
							// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							// 	type: 'success'
							// });
							this.$message.success(this.$t('tips.submitSuccess'));
						}
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				});
			},

			//查询数据
			serPageData() {
				this.pagination.current_page = 1;
				this.getPageData();
			},

			//请求分页数据
			getPageData() {
				// let _this = this;
				this.loading_load = true;

				this.$http
					.put(this.$urlConfig.WhSubUserPageList, {
						// sortAsc: this.filterData.sortAsc,
						// orderBy: this.filterData.orderBy,
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size, //当前页显示数目
						
						keyword: this.filterData.keyword ? this.filterData.keyword : null,
						// parentId: this.filterData.parentId ? this.filterData.parentId : null
						// serviceType: this.filterData.wh_op_service_type ? this.filterData.wh_op_service_type : null,
						// bizType: this.filterData.wh_biz_type ? this.filterData.wh_biz_type : null,
						// opModel: this.filterData.wh_op_type ? this.filterData.wh_op_type : null,
						// whNo: this.filterData.whNo ? this.filterData.whNo : null
					})
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						
						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},

			//选择数据后回调
			// selRow(event, row) {
			// 	event.stopPropagation();
			// 	this.$emit('selectRow', row);
			// },

			//查询数据字典
			// getDicData() {
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_biz_type', 'wh_op_type', 'wh_op_service_type'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// wh_biz_type    wh_op_type
			// 				this.selectOption.wh_biz_type = data.data['wh_biz_type'];
			// 				this.selectOption.wh_op_type = data.data['wh_op_type'];
			// 				this.selectOption.wh_op_service_type = data.data['wh_op_service_type'];

			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.submitError');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.submitRequestError'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
